<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        อนุมัติใบสั่งซื้อสินค้า : PU2-2
      </h3>
      <!-- <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button>
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          + เพิ่มข้อมูล
        </button>
      </div> -->
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>เลขที่เอกสาร</th>
              <th>รหัสผู้ขาย</th>
              <th>ชื่อผู้ขาย</th>
              <th>วันที่สร้างเอกสาร</th>
              <th>มูลค่า</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="7">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>
                <a style="font-weight: bold" @click="goToItemDetail(item)"
                  ><u>
                    {{ item.purchase_no ? item.purchase_no : "" }}
                  </u>
                </a>
              </td>
              <td>{{ item.vendor_code ? item.vendor_code : "" }}</td>
              <td>{{ item.vendor ? item.vendor.vendor_name : "" }}</td>
              <td>{{ formatDate(item.createdAt.substring(0, 10)) }}</td>
              <td>{{ formatPrice(item.final_price) }}</td>

              <td
                @click="
                  role === 'STAFF'
                    ? ''
                    : item.status === '0'
                    ? handleApprove(item)
                    : ''
                "
                :style="
                  item.is_cancel === '1'
                    ? 'cursor: ; color: FireBrick; font-weight: 500'
                    : item.is_cancel === '2'
                    ? 'cursor: ; color: grey; font-weight: 500'
                    : item.status === '1'
                    ? 'cursor: : ; color: #3ca76c; font-weight: 500'
                    : item.status === '0'
                    ? 'cursor: pointer; color: #0098DA; font-weight: 500'
                    : item.status === '3'
                    ? 'cursor: ; color: #3ca76c; font-weight: 500'
                    : 'cursor: ; color: red; font-weight: 500'
                "
              >
                {{
                  item.is_cancel === "1"
                    ? "ยกเลิกแล้ว"
                    : item.is_cancel === "2"
                    ? "Complete"
                    : item.status === "0"
                    ? "รออนุมัติการเพิ่ม"
                    : item.status === "2"
                    ? "ไม่อนุมัติ"
                    : "อนุมัติ"
                }}
              </td>
              <td>
                <div class="row">
                  <div class="col-sm-4">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToEdit(item.id)"
                    >
                      <!-- <i class="bi bi-eye-fill"></i> -->
                      <i
                        :class="
                          item.status === '0'
                            ? 'bi bi-pencil-square'
                            : 'bi bi-eye-fill'
                        "
                      ></i>
                    </button>
                  </div>

                  <div class="col-4">
                    <button
                      v-if="item.status === '0; font-weight: 500'"
                      :disabled="item.status === '1' ? true : false"
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>

                  <div @mousedown="hoverExportBtn()" class="col-sm-4">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingExport
                            ? ''
                            : index == indexExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-printer-fill"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <vue-barcode
        value="Hello, World!"
        :options="{ displayValue: false }"
      ></vue-barcode> -->
      <!-- {{ apiConfig.ENDPOINT }} -->
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import purchaseApi from "@/api/purchase/";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import moment from "moment";

import { Decode, Encode } from "@/services";

import pdfFile from "../../components/files/purchaseFile.vue";

import numbro from "numbro";

export default {
  components: {
    DialogConfirmByPass,
    Pagination,
    Loader,
    pdfFile,
  },
  setup() {
    document.title = "BELUCA | อนุมัติใบสั่งซื้อสินค้า";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    dialogConfirmByPass: false,
    isExport: false,
    loadingExport: false,
    indexExport: "",

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],

    dataExport: {
      id: 1,
      name: 2,
    },

    role: "",
  }),

  created() {
    this.role = Decode.decode(localStorage.getItem("role")).replace(
      /^"(.*)"$/,
      "$1"
    );

    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      const objQuery = {
        // status: "0",
        // status1: "1",
        companyId: companyId,
        companyBranchId: companyBranchId,
      };

      try {
        responseData = await purchaseApi.purchaseOrder.getAllByStatus(objQuery);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // const filterData = responseData.data.filter(
        //   (data) => data.status !== "3"
        // );

        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    gotoImportExcel() {
      // this.$router.push("/warehouse/WH4/importexcel");
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
        approved_date: moment(new Date()).format(),
      };

      updateResponse = await purchaseApi.purchaseOrder.update(
        id,
        this.itemApprove
      );
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.updateProduct(isApprove);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },

    async updateProduct(isApprove) {
      if (isApprove) {
        const updateResponse = await whApi.product.multiUpdate(
          this.itemApprove.purchaseOrderItems,
          "purchaseT"
        );

        if (updateResponse.response_status === "ERROR") {
          Swal.fire({
            icon: "error",
            html: `ปรับสต๊อกสินค้าไม่สำเร็จ !`,
            timer: 4000,
          });
        }
        // this.itemApprove = {
        //   ...this.itemApprove,
        //   status: isApprove ? 1 : 2,
        // };
        // for (const element of this.itemApprove.purchaseOrderItems) {
        //   await this.getOneProduct(element.product.id, element.item_amt);
        // }
      }
    },

    async getOneProduct(id, inTransit) {
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        // this.updateProductInTransit(inTransit, getOneResponse.data);
        this.updateProductInTransit(inTransit, getOneResponse.data, id);
      }
    },
    async updateProductInTransit(inTransit, item, id) {
      let updateResponse = [];
      const body = {
        // ...item,
        id: id,
        product_in_transit: item.product_in_transit
          ? parseInt(item.product_in_transit) + parseInt(inTransit)
          : parseInt(inTransit),
        isUpdateQty: true,
      };
      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        Swal.fire({
          icon: "error",
          html: `สินค้ารหัส : ${item.product_code} จำนวน: ${inTransit} เพิ่มเข้า IN TRANSIT ไม่สำเร็จ !`,
          timer: 4000,
        });
      }
    },

    // confirm by pass
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    // confirm by pass

    textSearch(val) {
      console.log({ val });
    },
    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },
    goToEdit(id) {
      this.$router.push({
        path: "/purchase/PU2-1/edit",
        query: {
          id: id,
          type: "PU2-2",
        },
      });
    },

    goToItemDetail(item) {
      this.$router.push({
        path: "/purchase/PU2-1/edit",
        query: {
          id: item.id,
        },
      });
    },

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },

    async search() {
      this.loading = true;
      let responseSearch = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      const objQuery = {
        // status: "0",
        // status1: "1",
        companyId: companyId,
        companyBranchId: companyBranchId,
      };

      this.searchInput
        ? (responseSearch = await purchaseApi.purchaseOrder.search(
            {
              search: this.searchInput,
            },
            objQuery
          ))
        : this.getAll();

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },
  },
};
</script>
